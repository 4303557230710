// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayAvatar-box {
    position: relative; /* Ensures the child SVGs are positioned relative to this container */
    margin: auto; /* Center the box horizontally if needed */
    padding: 1%; /* Remove default padding if any */
    display: flex;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
}

.displayAvatar-body {
    position: absolute; /* Allow overlapping */
    top: 3px;
}

.displayAvatar-clothes {
    position: absolute; /* Allow overlapping */
    top: 137px;
}

.displayAvatar-hair {
    position: absolute; /* Allow overlapping */
    top: -34px;
}

.displayAvatar-beard {
    position: absolute; /* Allow overlapping */
    top: 60px;
}

.displayAvatar-eyebrows {
    position: absolute; /* Allow overlapping */
    top: 50px;
}

.displayAvatar-eyes {
    position: absolute; /* Allow overlapping */
    top: 72px;
}

.displayAvatar-mouth {
    position: absolute; /* Allow overlapping */
    top: 115px;
}
`, "",{"version":3,"sources":["webpack://./src/pro/styles/displayAvatar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,qEAAqE;IACzF,YAAY,EAAE,0CAA0C;IACxD,WAAW,EAAE,kCAAkC;IAC/C,aAAa;IACb,uBAAuB,EAAE,+BAA+B;IACxD,mBAAmB,EAAE,6BAA6B;AACtD;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,QAAQ;AACZ;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,UAAU;AACd;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,UAAU;AACd;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,SAAS;AACb;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,SAAS;AACb;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,SAAS;AACb;;AAEA;IACI,kBAAkB,EAAE,sBAAsB;IAC1C,UAAU;AACd","sourcesContent":[".displayAvatar-box {\n    position: relative; /* Ensures the child SVGs are positioned relative to this container */\n    margin: auto; /* Center the box horizontally if needed */\n    padding: 1%; /* Remove default padding if any */\n    display: flex;\n    justify-content: center; /* Centers items horizontally */\n    align-items: center; /* Centers items vertically */\n}\n\n.displayAvatar-body {\n    position: absolute; /* Allow overlapping */\n    top: 3px;\n}\n\n.displayAvatar-clothes {\n    position: absolute; /* Allow overlapping */\n    top: 137px;\n}\n\n.displayAvatar-hair {\n    position: absolute; /* Allow overlapping */\n    top: -34px;\n}\n\n.displayAvatar-beard {\n    position: absolute; /* Allow overlapping */\n    top: 60px;\n}\n\n.displayAvatar-eyebrows {\n    position: absolute; /* Allow overlapping */\n    top: 50px;\n}\n\n.displayAvatar-eyes {\n    position: absolute; /* Allow overlapping */\n    top: 72px;\n}\n\n.displayAvatar-mouth {\n    position: absolute; /* Allow overlapping */\n    top: 115px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
